import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Grid2,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { LocationOn, Person, Edit, Notes } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { app } from "../App";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  setInitialValues,
  setSenderName,
  setSenderPhone,
  setReceiverName,
  setReceiverPhone,
  setTargetTime,
  setDropoffLocation,
  setDropoffCoordinates,
  setPickupCoordinates,
  setPickupLocation,
  setPickupNote,
  setLeaveAtTheDoor,
  setDropoffNote,
  setRequirePin,
  setSenderEmail,
  setPaymentUrl,
  setPreferredPaymentMethod,
  setDistanceInfo,
} from "../redux/deliverySlice";
import GoogleMap from "./GoogleMap";
import { CreditCard, Receipt } from "@mui/icons-material";
import MapsAutoComplete from "./MapsAutoComplete";
import {
  getCoordinatesByPlaceId,
  getDirectionsByPlaceIds,
} from "../utils/LocationFunctions";
import moment from "moment";
import DeliveryDetailsField from "./DeliveryDetailsField";
import colors from "../styles/colors";
// New imports
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate, Link, useParams } from "react-router-dom";
import ValidationDisplay, { validateFields } from "./ValidationDisplay";
import { createPaymentUrl, createPayment } from "../utils/PaymentFunctions";
import PaymentPopUp from "./PaymentPopUp";
import { calculatePrice } from "../utils/calculatePrice";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../App";
import { useAuth } from "../contexts/AuthContext"; // Import the custom hook

const DeliveryDetailsScreen = () => {
  const { currentUser } = useAuth(); // Access current user
  const { id } = useParams();
  const [validationErrors, setValidationErrors] = useState([]);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md")); // Move hook to top

  const [loading, setLoading] = useState(false);
  const [popUpState, setPopUpState] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const delivery = useSelector((state) => state.delivery.delivery);
  const {
    from,
    to,
    paymentUrl,
    toExtraInfo,
    extraInfo,
    deliveryId,
    deliveryTransportType,
    receiverName,
    receiverPhone,
    senderName,
    targetTime,
    price,
    senderPhone,
    distanceInfo,
    canBeLeftAtTheDoor,
    requirePin,
    senderEmail,
    coordinates,
    fromPlaceId,
    toPlaceId,
    paymentMethod,
  } = delivery;

  const fetchInitialValues = async (id) => {
    if (id && !initialized) {
      setInitialLoading(true);
      try {
        // Get reference to the user's document
        const fetchInitial = httpsCallable(
          getFunctions(app),
          "fetchInitialValue"
        );

        const result = await fetchInitial({ id });
        const success = result.data?.success;
        if (success) {
          const data = result.data?.data;
          console.log("data");
          dispatch(setInitialValues({ delivery: data }));
        }
        setInitialized(true);
        setInitialLoading(false);
      } catch (error) {
        console.log("Not found");
        setInitialized(true);
        setInitialLoading(false);
      }
    }
  };
  
  useEffect(() => {
    // if id and initialization not yet done fetch initial values from firestore
    // initialDelivery/id
    // setInitialValues({ delivery: initialDelivery });

    async function fetchData() {
      await fetchInitialValues(id);
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchDirections = async () => {
      if (fromPlaceId && toPlaceId) {
        const directions = await getDirectionsByPlaceIds(
          fromPlaceId,
          toPlaceId
        );
        if (directions) {
          // Dispatch the distance and duration to Redux
          dispatch(
            setDistanceInfo({
              distance: directions.distance.value, // meters
              duration: directions.duration.value, // seconds
            })
          );
          const estimateTargetTime = new Date(
            new Date().getTime() + directions.duration.value * 1000
          );
          const nowPlusSixHours = new Date(
            new Date().getTime() + 6 * 60 * 60 * 1000
          );
          if (estimateTargetTime.getTime() > nowPlusSixHours.getTime()) {
            const targetEstimatePlusTwoHours = new Date(
              estimateTargetTime.getTime() + 2 * 60 * 60 * 1000
            );
            dispatch(setTargetTime({ targetTime: targetEstimatePlusTwoHours }));
          }
        }
      }
    };
    fetchDirections();
  }, [fromPlaceId, toPlaceId]);

  const saveDelivery = async (delivery) => {
    const deliveryData = {
      ...delivery,
      status: "active", // Set an initial status for active delivery
      createdAt: new Date().getTime(),
    };

    // Check if there is a current user logged in
    if (currentUser?.uid) {
      const userRef = doc(db, "users", currentUser.uid);

      try {
        // Check if user document already exists
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          // Update the existing user document with new delivery and updated timestamp
          await updateDoc(userRef, {
            email: senderEmail || null,
            phone: senderPhone || null,
            location: delivery.geolocation || {}, // Assuming `from` is the pickup location
            my_active_deliveries: arrayUnion(deliveryData), // Add the new delivery to active deliveries
            updated: serverTimestamp(),
          });
          return true;
        } else {
          // Create a new user document if it doesn't exist
          await setDoc(userRef, {
            email: senderEmail || null,
            phone: senderPhone || null,
            location: delivery.geolocation || {}, // Assuming `from` is the pickup location
            my_active_deliveries: [deliveryData], // Start with the current delivery
            updated: serverTimestamp(),
          });
          return true;
        }
      } catch (error) {
        console.error("Error saving user data and delivery:", error);
        return false;
      }
    } else {
      console.warn("No authenticated user found.");
      return false;
    }
  };

  const handleRequestDeliveryClick = async () => {
    const errors = validateFields(delivery);
    if (errors.length > 0) {
      setValidationErrors(errors);
      setLoading(false);
      return;
    }

    console.log("sending", delivery);

    if (paymentMethod === "card") {
      createPayment({ ...delivery, paymentUrl });

      const newWindow = window.open(
        paymentUrl,
        "_blank",
        "width=800,height=600,top=100,left=100,resizable,scrollbars=yes,status=yes"
      );

      // Check if the new window opened successfully
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === "undefined"
      ) {
        setPopUpState(true); // Show the pop-up dialog if the new window didn't open
      } else {
        setLoading(false);
      }
    }
    console.log("making real delivery");
    createPayment(delivery);
    const resp = await saveDelivery(delivery);
    console.log("resp ...", resp);
    setLoading(false);

    console.log("paymentMethod", paymentMethod);
    if (paymentMethod === "prePaid") {
      navigate(`/dc/${id}`);
    } else {
      navigate("/dc");
    }
  };

  const renderMeasurementGuideLines = (deliveryTransportType) => {
    // Define maximum limits for each transport type
    const guidelines = {
      public: {
        maxWeight: "20",
        maxHeight: "50",
        maxWidth: "50",
        maxLength: "58",
        maxAmount: "1",
      },
      car: {
        maxWeight: "60",
        maxLength: "150",
        maxAmount: "2",
      },
      van: {
        maxWeight: "500",
        maxHeight: "200",
        maxAmount: "n",
      },
    };

    // Get the guidelines for the specified transport type
    const currentGuidelines = guidelines[deliveryTransportType] || {};

    // Return the measurement guidelines as a styled list
    return (
      <List dense>
        <ListItem>
          <Typography variant="body1">
            {`${t("deliveryDetailsScreen.maxWeight")}: ${
              currentGuidelines.maxWeight || t("n/a")
            } kg`}
          </Typography>
        </ListItem>
        {currentGuidelines.maxHeight && (
          <ListItem>
            <Typography variant="body1">
              {`${t("deliveryDetailsScreen.maxHeight")}: ${
                currentGuidelines.maxHeight || t("n/a")
              } cm`}
            </Typography>
          </ListItem>
        )}
        {currentGuidelines.maxWidth && (
          <ListItem>
            <Typography variant="body1">
              {`${t("deliveryDetailsScreen.maxWidth")}: ${
                currentGuidelines.maxWidth || t("n/a")
              } cm`}
            </Typography>
          </ListItem>
        )}
        {currentGuidelines.maxLength && (
          <ListItem>
            <Typography variant="body1">
              {`${t("deliveryDetailsScreen.maxLength")}: ${
                currentGuidelines.maxLength || t("n/a")
              } cm`}
            </Typography>
          </ListItem>
        )}
        <ListItem>
          <Typography variant="body1">
            {`${t("deliveryDetailsScreen.maxAmount")}: ${
              currentGuidelines.maxAmount || t("n/a")
            } kpl`}
          </Typography>
        </ListItem>
      </List>
    );
  };
  const fetchPaymentUrl = async () => {
    const adjustedPrice = calculatePrice(delivery) * 1.255;
    const url = await createPaymentUrl({
      deliveryId,
      price: adjustedPrice,
      email: senderEmail,
    });
    dispatch(setPaymentUrl(url));
  };

  const [editingFieldOpen, setEditingFieldOpen] = useState({
    pickupTime: false,
    targetTime: false,
    pickupNote: false,
    dropoffNote: false,

    receiverDetails: false,
    senderDetails: false,
    from: false,
    to: false,
  });

  const [paymentMethodLocal, setPaymentMethod] = useState(paymentMethod);

  const handlePaymentChange = (event) => {
    setPaymentMethod(event.target.value);
    dispatch(setPreferredPaymentMethod(event.target.value));
  };

  const renderIcon = () => {
    switch (paymentMethod) {
      case "card":
        return <CreditCard sx={{ color: "blue", mr: 1 }} />;
      case "bill":
        return <Receipt sx={{ color: "purple", mr: 1 }} />;
      case "prePaid":
        return <Receipt sx={{ color: "green", mr: 1 }} />; // Using Receipt icon in green
      default:
        return null;
    }
  };
  const renderText = () => {
    switch (paymentMethod) {
      case "card":
        return t(`deliveryDetailsScreen.card`);
      case "bill":
        return t(`deliveryDetailsScreen.bill`);
      case "prePaid":
        return t(`deliveryDetailsScreen.prePaid`);
      default:
        return null;
    }
  };

  const [time, setTime] = useState(
    targetTime
      ? new Date(targetTime * 1000).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })
      : "10:00"
  );

  const handleTimeChange = (e) => {
    const newTime = e.target.value;
    setTime(newTime);

    const originalDate = new Date(targetTime); // Convert targetTime to Date object
    const day = originalDate.getDate();
    const month = originalDate.getMonth() + 1;
    const year = originalDate.getFullYear();

    const newTargetTime = moment(
      `${year}-${month}-${day} ${newTime}`,
      "YYYY-M-D HH:mm"
    ).valueOf(); // Get Unix timestamp in milliseconds

    dispatch(setTargetTime({ targetTime: newTargetTime })); // Update state in Redux
  };

  const setFromExtraInfo = (tex) => {
    dispatch(setPickupNote(tex));
  };

  const handleEmailAuthentication = async (email) => {
    const password = Math.random().toString(36).slice(-8); // Generate a random password

    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      // If user doesn't exist, create a new user with email and password
      try {
        await createUserWithEmailAndPassword(auth, email, password);
      } catch (creationError) {
        console.error("Error creating user:", creationError);
      }
    }
  };

  const toggleEditingFields = (field, status) => {
    const closeOthers = Object.keys(editingFieldOpen).map((key) => ({
      [key]: false,
    }));
    setEditingFieldOpen({ ...closeOthers, [field]: status });
    if (senderEmail && senderEmail.length > 5 && paymentUrl === null) {
      fetchPaymentUrl(); // Call the function to fetch the URL
    }
    if (field === "senderDetails" || field === "receiverDetails") {
      if (currentUser) {
        saveDelivery(delivery);
      } else {
        if (senderEmail && senderEmail.length > 5) {
          handleEmailAuthentication(senderEmail); // Only create or log in if no user
        }
      }
    }
  };

  const handleDropoffChange = async (location) => {
    if (location) {
      const { description: address, place_id: placeId } = location;
      dispatch(setDropoffLocation({ address, placeId })); // Save to Redux

      // Fetch coordinates for the selected placeId
      const coordinates = await getCoordinatesByPlaceId(placeId);
      if (coordinates) {
        dispatch(setDropoffCoordinates(coordinates)); // Save coordinates to Redux
      }
    } else {
      dispatch(setDropoffLocation({ address: "", placeId: "" })); // Clear the value
      dispatch(setDropoffCoordinates({ latitude: null, longitude: null })); // Clear coordinates
    }
  };

  const handlePickupChange = async (location) => {
    if (location) {
      const { description: address, place_id: placeId } = location;
      dispatch(setPickupLocation({ address, placeId })); // Save to Redux

      // Fetch coordinates for the selected placeId
      const coordinates = await getCoordinatesByPlaceId(placeId);
      if (coordinates) {
        dispatch(setPickupCoordinates(coordinates)); // Save coordinates to Redux
      }
    } else {
      dispatch(setPickupLocation({ address: "", placeId: "" })); // Clear the value
      dispatch(setPickupCoordinates({ latitude: null, longitude: null })); // Clear coordinates
    }
  };

  if (initialLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <CircularProgress size={40} />
        <Typography variant="h6" color="textSecondary">
          {t("loading")}
        </Typography>
      </Box>
    );
  }
  console.log("loading", loading);
  return (
    <Box /* height="100%" */>
   
      <Grid2 container>
      <ValidationDisplay
        errors={validationErrors}
        onClose={() => setValidationErrors([])}
      />
        {/* Left Side - Details */}
        <Grid2 item size={{ xs: 12, md: 6 }} sx={{ p: "1%", pb: 0 }}>

          <Box>
            {/* Package Info */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 2,
                backgroundColor: "#f5f5f5", // Light background for the package info
                borderRadius: 2,
                mb: 3,
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                {t("package")} {/* Localized text */}
              </Typography>

              {/* Editable time and date */}

              {editingFieldOpen.targetTime ? (
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      value={moment(targetTime)}
                      onChange={(newValue) => {
                        if (newValue && newValue.isValid()) {
                          dispatch(
                            setTargetTime({ targetTime: newValue.valueOf() })
                          );
                        }
                      }}
                      format="DD.MM.YYYY HH:mm"
                      ampm={false}
                      minDateTime={moment()}
                    />
                  </LocalizationProvider>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => toggleEditingFields("targetTime", false)}
                  >
                    {t("save")}
                  </Button>
                </Box>
              ) : (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  onClick={() => toggleEditingFields("targetTime", true)}
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    textAlign: "center",
                  }}
                >
                  {t("deliveredBy")}{" "}
                  {moment(targetTime).format("HH:mm DD.MM.YYYY")}
                </Typography>
              )}
              <Typography variant="h6" fontWeight="bold">
                {price ? `${calculatePrice(delivery) * 1.25}€` : ""}
              </Typography>
            </Box>
            {/* Pickup Details */}
            <Card variant="outlined" sx={{ mb: 2, p: 2, borderRadius: "16px" }}>
              <CardContent>
                <Typography variant="h6" fontWeight="bold">
                  {t("pickupDetails")} {/* Localized text */}
                </Typography>
                {!editingFieldOpen.from ? (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ maxWidth: "60%", overflow: "hidden" }}
                    >
                      <LocationOn sx={{ mr: 1 }} />
                      <Typography variant="body1">{from}</Typography>
                    </Box>
                    <Button
                      startIcon={<Edit />}
                      variant="outlined"
                      onClick={() => toggleEditingFields("from", true)}
                    >
                      {t("edit")} {/* Localized text */}
                    </Button>
                  </Box>
                ) : (
                  <div style={{ marginTop: 10 }}>
                    <MapsAutoComplete
                      label={t("pickupPlaceholder")}
                      value={from}
                      onChange={(location) => {
                        if (location) {
                          handlePickupChange(location); // Dispatch the selected location
                        } else {
                          handlePickupChange({ description: "" }); // Clear the pickup location
                        }
                      }}
                      isMobile={false}
                    />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      sx={{ mt: 2 }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => {
                          toggleEditingFields("from", false);
                        }}
                        sx={{ ml: 2 }}
                      >
                        {t("save")} {/* Localized text */}
                      </Button>
                    </Box>
                  </div>
                )}
                <DeliveryDetailsField
                  filledText={senderName}
                  placeHolder={t("senderDetails")}
                  editingFieldOpen={editingFieldOpen}
                  toggleEditingFields={toggleEditingFields}
                  fieldType="senderDetails"
                  icon={<Person sx={{ mr: 1 }} />}
                  editingFieldConfig={[
                    {
                      type: "TextField",
                      placeHolder: t("senderName"),
                      value: senderName,
                      onChange: (value) => dispatch(setSenderName(value)),
                    },
                    {
                      type: "PhoneNumber",
                      placeHolder: t("senderPhone"),
                      value: senderPhone,
                      onChange: (value) => dispatch(setSenderPhone(value)),
                    },
                    {
                      type: "EmailField",
                      placeHolder: t("email"),
                      value: senderEmail,
                      onChange: (value) => dispatch(setSenderEmail(value)),
                    },
                  ]}
                  t={t}
                />
                <DeliveryDetailsField
                  filledText={extraInfo}
                  placeHolder={t("pickupNote")}
                  editingFieldOpen={editingFieldOpen}
                  toggleEditingFields={toggleEditingFields}
                  fieldType="pickupNote"
                  icon={<Notes sx={{ mr: 1 }} />}
                  t={t}
                  editingFieldConfig={[
                    {
                      type: "TextFieldMultiline",
                      placeHolder: t("pickupNoteDetails"),
                      value: extraInfo,
                      onChange: setFromExtraInfo,
                    },
                  ]}
                />
                {/* Pickup Note */}
              </CardContent>
            </Card>

            {/* Dropoff Details */}
            <Card variant="outlined" sx={{ mb: 2, p: 2, borderRadius: "16px" }}>
              <CardContent>
                <Typography variant="h6" fontWeight="bold">
                  {t("dropOffDetails")} {/* Localized text */}
                </Typography>
                {!editingFieldOpen.to ? (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box display="flex" alignItems="center">
                      <LocationOn sx={{ mr: 1 }} />
                      <Typography variant="body1">{to}</Typography>
                    </Box>
                    <Button
                      startIcon={<Edit />}
                      variant="outlined"
                      onClick={() => toggleEditingFields("to", true)}
                    >
                      {t("edit")} {/* Localized text */}
                    </Button>
                  </Box>
                ) : (
                  <>
                    <MapsAutoComplete
                      label={t("dropoffPlaceholder")}
                      value={to}
                      onChange={(location) => {
                        if (location) {
                          handleDropoffChange(location); // Dispatch the selected location
                        } else {
                          handleDropoffChange({ description: "" }); // Clear the dropoff location
                        }
                      }}
                      isMobile={false}
                    />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      sx={{ mt: 2 }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => toggleEditingFields("to", false)}
                      >
                        {t("save")} {/* Localized text */}
                      </Button>
                    </Box>
                  </>
                )}

                <DeliveryDetailsField
                  filledText={receiverName}
                  placeHolder={t("receiverDetails")}
                  editingFieldOpen={editingFieldOpen}
                  toggleEditingFields={toggleEditingFields}
                  fieldType="receiverDetails"
                  icon={<Person sx={{ mr: 1 }} />}
                  t={t}
                  editingFieldConfig={[
                    {
                      type: "TextField",
                      placeHolder: t("receiverName"),
                      value: receiverName,
                      onChange: (value) => dispatch(setReceiverName(value)),
                    },
                    {
                      type: "PhoneNumber",
                      placeHolder: t("receiverPhone"),
                      value: receiverPhone,
                      onChange: (value) => dispatch(setReceiverPhone(value)),
                    },
                  ]}
                />
                <DeliveryDetailsField
                  filledText={toExtraInfo}
                  placeHolder={t("dropoffNote")}
                  editingFieldOpen={editingFieldOpen}
                  toggleEditingFields={toggleEditingFields}
                  fieldType="dropoffNote"
                  icon={<Notes sx={{ mr: 1 }} />}
                  t={t}
                  editingFieldConfig={[
                    {
                      type: "TextFieldMultiline",
                      placeHolder: t("dropoffNoteDetails"),
                      value: toExtraInfo,
                      onChange: (text) => dispatch(setDropoffNote(text)),
                    },
                    {
                      type: "Switch",
                      placeHolder: t("canBeLeftAtTheDoor"),
                      value: canBeLeftAtTheDoor,
                      onChange: () =>
                        dispatch(setLeaveAtTheDoor(!canBeLeftAtTheDoor)),
                    },
                    {
                      type: "Switch",
                      placeHolder: t("requiresPIN"),
                      value: requirePin,
                      onChange: () => dispatch(setRequirePin(!requirePin)),
                    },
                  ]}
                />
              </CardContent>
            </Card>

            <Box
              sx={{
                mb: { xs: 2, md: 10 }, // 2 for small screens, 10 for medium and up
                p: 2,
                border: "1px solid #ccc",
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                {t("packageGuidelines")} {/* Localized text */}
              </Typography>
              {renderMeasurementGuideLines(deliveryTransportType)}
              <Typography variant="body2">
                {t("guidelinesContent")}{" "}
                {/* Localized text for package guidelines */}
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                {t("agreementNote")}{" "}
                {/* Localized text encouraging agreement */}
                <Link to="/terms-and-conditions" target="_blank" rel="noopener">
                  {t("termsAndConditions")}{" "}
                  {/* Localized text for terms and conditions */}
                </Link>
              </Typography>
            </Box>
            <Box
              sx={{
                position: "fixed", // Sticks the box to the bottom of the screen
                bottom: 0, // Aligns to the bottom
                left: 0,
                right: 0,
                p: "2%",
                backgroundColor: "white", // Ensure background is white
                zIndex: 1000, // Makes sure it stays above other content
                boxShadow: "0 -2px 10px rgba(0,0,0,0.1)", // Adds a slight shadow for separation
              }}
            >
              {/* Payment Method Section */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#f5f5f5", // Light background for the payment method
                }}
              >
                {/* Localized payment method */}
                {/* Dropdown to change the payment method */}
                <FormControl fullWidth>
                  <InputLabel id="payment-method-label">
                    {t("deliveryDetailsScreen.paymentMethod")}
                  </InputLabel>
                  <Select
                    display="flex"
                    fullWidth
                    labelId="payment-method-label"
                    value={paymentMethod}
                    onChange={handlePaymentChange}
                    label="Change Method"
                    renderValue={() => (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: "100%" }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{ flex: 1, overflow: "auto" }}
                        >
                          {renderIcon()}
                          <Typography
                            sx={{
                              ml: 1,
                              whiteSpace: "nowrap",
                              overflow: "auto",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {renderText()}
                          </Typography>
                        </Box>
                        <Box sx={{ ml: 2 }}>
                          <Typography>
                            {t("deliveryDetailsScreen.choose")}{" "}
                            {/* Localized text */}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  >
                    <MenuItem value="card">
                      {t("deliveryDetailsScreen.card")}
                    </MenuItem>
                    <MenuItem value="bill">
                      {t("deliveryDetailsScreen.bill")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <PaymentPopUp
                paymentUrl={paymentUrl}
                setPopUpState={setPopUpState}
                popUpState={popUpState}
              />
              {/* Request Delivery Button */}
              <Button
                variant="contained"
                fullWidth
                onClick={handleRequestDeliveryClick}
                sx={{
                  mt: 1,
                  p: 2,
                  backgroundColor: colors.primary, // Use primary color from colors.js
                  color: colors.textPrimary, // Set text color
                  "&:hover": {
                    backgroundColor: "#444", // Custom hover color
                  },
                }}
                disabled={loading} // Disable the button while loading
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t("requestDelivery")
                )}
              </Button>
            </Box>
          </Box>
        </Grid2>

        {/* Right Side - Map */}
        <Grid2
          item
          size={{ md: 6 }}
          sx={{
            backgroundColor: "rgba(128, 128, 128, 0.1)",
            mb: "16px",
            p: "1%",
            border: "1px rgba(128, 128, 128, 0.1)",
            borderRadius: "16px",
            maxHeight: "630px", // Set your desired max height here
            overflow: "auto", // Add this to allow scrolling if the content exceeds the max height
          }}
        >
          {isDesktop && <GoogleMap coordinates={coordinates} />}
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default DeliveryDetailsScreen;
