import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Button,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Select,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
  Paper,
  Stack,
  Collapse,
  FormHelperText,
} from "@mui/material";
import {
  CalendarToday,
  AccessTime,
  ShoppingBag,
  DirectionsCar,
  LocalShipping,
  MyLocation,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setPickupLocation,
  setDropoffLocation,
  setPickUpTime,
  setDeliveryTransportType,
  setPickupCoordinates,
  setDropoffCoordinates,
  setDistanceInfo,
  setTargetTime,
} from "../redux/deliverySlice";
import MapsAutoComplete from "./MapsAutoComplete";
import {
  getCoordinatesByPlaceId,
  getDirectionsByPlaceIds,
} from "../utils/LocationFunctions";
import { generateTimeOptions } from "../utils/TimeFunctions";
import colors from "../styles/colors";

const LocationScreen = ({ onSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [timeEdited, setTimeEdited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Get current state from Redux
  const delivery = useSelector((state) => state.delivery.delivery);
  const {
    from,
    fromPlaceId,
    to,
    toPlaceId,
    pickUpTime,
    deliveryTransportType,
  } = delivery;
  const [date, setDate] = useState(
    pickUpTime
      ? new Date(pickUpTime).toISOString().slice(0, 10)
      : new Date().toISOString().slice(0, 10)
  );

  useEffect(() => {
    const fetchDirections = async () => {
      if (fromPlaceId && toPlaceId) {
        setIsLoading(true);
        try {
          const directions = await getDirectionsByPlaceIds(
            fromPlaceId,
            toPlaceId
          );
          if (directions) {
            // Dispatch the distance and duration to Redux
            dispatch(
              setDistanceInfo({
                distance: directions.distance.value, // meters
                duration: directions.duration.value, // seconds
              })
            );
            const estimateTargetTime = new Date(
              new Date().getTime() + directions.duration.value * 1000
            );
            const nowPlusSixHours = new Date(
              new Date().getTime() + 6 * 60 * 60 * 1000
            );
            if (estimateTargetTime.getTime() > nowPlusSixHours.getTime()) {
              const targetEstimatePlusTwoHours = new Date(
                estimateTargetTime.getTime() + 2 * 60 * 60 * 1000
              );
              dispatch(
                setTargetTime({ targetTime: targetEstimatePlusTwoHours })
              );
            }
          }
        } catch (error) {
          console.error("Error fetching directions:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchDirections();
  }, [fromPlaceId, toPlaceId, dispatch]);

  const handleSizeChange = (event, newSize) => {
    if (newSize !== null) {
      dispatch(setDeliveryTransportType(newSize)); // Update Redux state for transport type
    }
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
    dispatch(setPickUpTime({ pickUpTime: new Date(e.target.value).getTime() })); // Convert to Unix timestamp and update state
    const duration = delivery.duration ? delivery.duration : 0;
    const twoHours = 2 * 60 * 60 * 1000;
    const targeTimeEstimate = new Date(e.target.value).getTime() + duration + twoHours;
    dispatch(setTargetTime({ targetTime: targeTimeEstimate })); // Convert to Unix timestamp and update state
  };

  const handleTimeChange = (e) => {
    setTimeEdited(true);
    dispatch(
      setPickUpTime({
        pickUpTime: new Date(`${date}T${e.target.value}`).getTime(),
      })
    )
    const duration = delivery.duration ? delivery.duration : 0;
    const twoHours = 2 * 60 * 60 * 1000;
    const targeTimeEstimate = new Date(`${date}T${e.target.value}`).getTime() + duration + twoHours;
    dispatch(setTargetTime({ targetTime: targeTimeEstimate })); // Convert to Unix timestamp and update state
  };

  const handlePickupChange = async (location) => {
    if (location) {
      const { description: address, place_id: placeId } = location;
      dispatch(setPickupLocation({ address, placeId })); // Save to Redux

      // Fetch coordinates for the selected placeId
      const coordinates = await getCoordinatesByPlaceId(placeId);
      if (coordinates) {
        dispatch(setPickupCoordinates(coordinates)); // Save coordinates to Redux
      }
    } else {
      dispatch(setPickupLocation({ address: "", placeId: "" })); // Clear the value
      dispatch(setPickupCoordinates({ latitude: null, longitude: null })); // Clear coordinates
    }
  };

  const handleDropoffChange = async (location) => {
    if (location) {
      const { description: address, place_id: placeId } = location;
      dispatch(setDropoffLocation({ address, placeId })); // Save to Redux

      // Fetch coordinates for the selected placeId
      const coordinates = await getCoordinatesByPlaceId(placeId);
      if (coordinates) {
        dispatch(setDropoffCoordinates(coordinates)); // Save coordinates to Redux
      }
    } else {
      dispatch(setDropoffLocation({ address: "", placeId: "" })); // Clear the value
      dispatch(setDropoffCoordinates({ latitude: null, longitude: null })); // Clear coordinates
    }
  };

  const submitText =
    deliveryTransportType === "public" ? t("buttonOther") : t("buttonOther");

  const sizeOptions = [
    { value: "public", icon: <ShoppingBag />, label: t("smallSize") },
    { value: "car", icon: <DirectionsCar />, label: t("mediumSize") },
    { value: "van", icon: <LocalShipping />, label: t("largeSize") },
  ];

  return (
    <Paper
      elevation={0}
      sx={{
        p: { xs: 1, sm: 2 },
        backgroundColor: "transparent",
      }}
    >
      <Stack spacing={isMobile ? 1.5 : 2}>
        {/* Pickup Location with Current Location Button */}
        <Box sx={{ position: "relative" }}>
          <MapsAutoComplete
            label={t("pickupPlaceholder")}
            value={from}
            onChange={(location) => {
              if (location) {
                handlePickupChange(location);
              } else {
                handlePickupChange({ description: "" });
              }
            }}
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
                backgroundColor: "#ffffff",
              },
            }}
          />
        </Box>

        {/* Dropoff Location */}
        <MapsAutoComplete
          label={t("dropoffPlaceholder")}
          value={to}
          onChange={(location) => {
            if (location) {
              handleDropoffChange(location);
            } else {
              handleDropoffChange({ description: "" });
            }
          }}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: 2,
              backgroundColor: "#ffffff",
            },
          }}
        />

        {/* Date and Time Selection - Responsive layout */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2, // This replaces your spacer div with MUI's built-in spacing
          }}
        >
          <TextField
            fullWidth
            type="date"
            variant="outlined"
            value={date}
            onChange={handleDateChange}
            InputProps={{
              startAdornment: isMobile ? null : (
                <IconButton size="small">
                  <CalendarToday fontSize="small" />
                </IconButton>
              ),
              sx: {
                borderRadius: 2,
                backgroundColor: "#ffffff",
                height: isMobile ? "48px" : "56px",
                p: isMobile ? "0 8px" : "0 14px",
              },
            }}
            size={isMobile ? "small" : "medium"}
          />

          <Select
            fullWidth
            value={
              pickUpTime
                ? new Date(pickUpTime).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })
                : ""
            }
            onChange={handleTimeChange}
            displayEmpty
            variant="outlined"
            size={isMobile ? "small" : "medium"}
            startAdornment={
              isMobile ? null : (
                <IconButton size="small">
                  <AccessTime fontSize="small" />
                </IconButton>
              )
            }
            renderValue={(selected) => {
              return timeEdited ? selected : t("now");
            }}
            sx={{
              borderRadius: 2,
              height: isMobile ? "48px" : "56px",
              backgroundColor: "#ffffff",
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: isMobile ? 36 * 4.5 : 48 * 4.5,
                },
              },
            }}
          >
            {generateTimeOptions().map((timeOption) => (
              <MenuItem key={timeOption} value={timeOption} dense={isMobile}>
                {timeOption}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {/* Size Selection - Vertical buttons on mobile */}
        <Box sx={{ my: 1 }}>
          {isMobile ? (
            <Stack spacing={1}>
              {sizeOptions.map((option) => (
                <Button
                  key={option.value}
                  variant={
                    deliveryTransportType === option.value
                      ? "contained"
                      : "outlined"
                  }
                  startIcon={option.icon}
                  onClick={() =>
                    dispatch(setDeliveryTransportType(option.value))
                  }
                  fullWidth
                  sx={{
                    justifyContent: "flex-start",
                    p: 1.5,
                    borderRadius: 2,
                    backgroundColor:
                      deliveryTransportType === option.value
                        ? colors.primary
                        : "transparent",
                    color:
                      deliveryTransportType === option.value
                        ? colors.textPrimary
                        : "inherit",
                    borderColor: colors.primary,
                    "&:hover": {
                      backgroundColor:
                        deliveryTransportType === option.value
                          ? colors.primary
                          : "rgba(0,0,0,0.04)",
                    },
                  }}
                >
                  {option.label}
                </Button>
              ))}
            </Stack>
          ) : (
            <ToggleButtonGroup
              value={deliveryTransportType}
              exclusive
              onChange={handleSizeChange}
              aria-label={t("deliverySize")}
              sx={{
                width: "100%",
                display: "flex",
                "& .MuiToggleButton-root": {
                  flex: 1,
                  p: 1,
                  borderRadius: 2,
                  border: `1px solid ${colors.primary}`,
                  m: 0.5,
                  "&.Mui-selected": {
                    backgroundColor: colors.primary,
                    color: colors.textPrimary,
                    "&:hover": {
                      backgroundColor: colors.primary,
                    },
                  },
                },
              }}
            >
              {sizeOptions.map((option) => (
                <ToggleButton key={option.value} value={option.value}>
                  {option.icon} <Box sx={{ ml: 1 }}>{option.label}</Box>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        </Box>

        {/* Size details - shows description of the selected size */}
        <Collapse in={Boolean(deliveryTransportType)}>
          <FormHelperText
            sx={{
              textAlign: "center",
              fontSize: "0.875rem",
              color: "text.secondary",
              mt: 0.5,
            }}
          >
            {deliveryTransportType === "public" && t("smallSizeDetails")}
            {deliveryTransportType === "car" && t("mediumSizeDetails")}
            {deliveryTransportType === "van" && t("largeSizeDetails")}
          </FormHelperText>
        </Collapse>

        {/* Submit Button */}
        <Button
          variant="contained"
          fullWidth
          sx={{
            py: isMobile ? 1.5 : 2,
            mt: 1,
            backgroundColor: colors.primary,
            color: colors.textPrimary,
            borderRadius: 2,
            boxShadow: 2,
            "&:hover": {
              backgroundColor: colors.primary,
              opacity: 0.9,
            },
          }}
          onClick={onSubmit}
          disabled={isLoading || !from || !to}
        >
          {isLoading ? t("loading") : submitText}
        </Button>
      </Stack>
    </Paper>
  );
};

export default LocationScreen;
