import React from "react";
import LocationScreen from "./LocationScreen";
import { Card, CardContent, useMediaQuery, useTheme, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FrontPageSlogan from "./FrontPageSlogan";
import {
  logFirebaseEvent,
  logGoogleAdsConversion,
} from "../utils/AnalyticsFunctions";

const CreateDeliveryContainer = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const seePrices = () => {
    logFirebaseEvent("seePrices");

    // This is for the Google Ads conversion tracking
    logGoogleAdsConversion("siirtyminen_play__tai_app_storeen");

    // Navigate to the next page
    navigate("/d");
  };

  return (
    <Box 
      sx={{ 
        px: { xs: 1, sm: 2, md: 3 },
        width: "100%",
        maxWidth: "100vw"
      }}
    >
      <Card
        sx={{
          padding: { xs: 1, sm: 2 },
          mt: { xs: 1, sm: 2 },
          backgroundColor: "#f5f5f5",
          borderRadius: { xs: 3, sm: 4 },
          boxShadow: { xs: 1, sm: 3 },
          maxWidth: { xs: "100%", md: 600 },
          margin: "0 auto",
          overflow: "visible"
        }}
        elevation={isMobile ? 1 : 3}
      >
        <CardContent sx={{ p: { xs: 1, sm: 2 } }}>
          <FrontPageSlogan />
          <LocationScreen onSubmit={seePrices} />
        </CardContent>
      </Card>
    </Box>
  );
};

export default CreateDeliveryContainer;