import React, { useState, useEffect } from "react";
import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import { keyframes } from "@emotion/react";
import { useTranslation } from "react-i18next";

// Define keyframe animation for fading out
const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

// Define keyframe animation for fading in
const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const FrontPageSlogan = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Array of different slogans for marketing use cases
  const slogans = [
    t("slogan"),
    t("marketingTextLanding1"),
    t("marketingTextLanding2"),
    t("marketingTextLanding3"),
    t("marketingTextLanding4"),
    t("marketingTextLanding5"),
  ];

  const [currentSloganIndex, setCurrentSloganIndex] = useState(0);
  const [animationState, setAnimationState] = useState("visible"); // "visible", "fading-out", "fading-in"
  const [intervalTime, setIntervalTime] = useState(4000);

  useEffect(() => {
    // Set an interval to change the slogan
    const interval = setInterval(() => {
      // Start fade out
      setAnimationState("fading-out");
      
      // After fade out completes, change slogan and start fade in
      setTimeout(() => {
        setCurrentSloganIndex((prevIndex) => (prevIndex + 1) % slogans.length);
        setAnimationState("fading-in");
        
        // After fade in completes, set to visible
        setTimeout(() => {
          setAnimationState("visible");
        }, 300);
        
        // Increase the interval time up to a max of 10000ms (10 seconds)
        setIntervalTime((prevTime) => Math.min(prevTime + 1000, 10000));
      }, 500);
    }, intervalTime);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [slogans.length, intervalTime]);

  // Define animation style based on current state
  const getAnimationStyle = () => {
    if (animationState === "fading-out") {
      return `${fadeOut} 0.5s ease forwards`;
    } else if (animationState === "fading-in") {
      return `${fadeIn} 0.3s ease forwards`;
    }
    return "none";
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: { xs: isMobile ? "8vh" : "10vh", sm: "15vh", md: "20vh" },
        textAlign: "center",
        mb: { xs: 1, sm: 2 },
        overflow: "hidden"
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        sx={{
          fontWeight: "bold",
          animation: getAnimationStyle(),
          opacity: animationState === "visible" ? 1 : undefined,
          fontSize: { 
            xs: "1.25rem", 
            sm: "1.75rem", 
            md: "2.25rem", 
            lg: "2.75rem" 
          },
          lineHeight: 1.2,
          maxWidth: "90%",
          margin: "0 auto",
          transition: "font-size 0.3s ease",
          color: theme.palette.primary.main
        }}
      >
        {slogans[currentSloganIndex]}
      </Typography>
    </Box>
  );
};

export default FrontPageSlogan;